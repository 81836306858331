exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-es-404-js": () => import("./../../../src/pages/es/404.js" /* webpackChunkName: "component---src-pages-es-404-js" */),
  "component---src-pages-es-search-result-page-html-js": () => import("./../../../src/pages/es/search-result-page.html.js" /* webpackChunkName: "component---src-pages-es-search-result-page-html-js" */),
  "component---src-pages-es-sitemap-js": () => import("./../../../src/pages/es/sitemap.js" /* webpackChunkName: "component---src-pages-es-sitemap-js" */),
  "component---src-pages-search-result-page-html-js": () => import("./../../../src/pages/search-result-page.html.js" /* webpackChunkName: "component---src-pages-search-result-page-html-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-templates-lds-page-js": () => import("./../../../src/templates/lds-page.js" /* webpackChunkName: "component---src-templates-lds-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */)
}

